import React, { useEffect } from 'react'
import first from 'lodash/first'
import { useDispatch, useSelector } from 'react-redux'

import RadioButton from '../common/RadioButton'
import { OVERFLOW_OPTIONS, OverflowOption } from '../../interfaces/OverflowOption'
import { UsersState, slackMessageAction, usersSelector } from '../../hasura/slices/users'
import { updateOverflowRuleAction } from '../../hasura/slices/enterprises'

// @ts-ignore
import overflow from '../../lib/images/overflow.svg'

export default function OverflowSwitch() {
  const dispatch = useDispatch()

  const { accessToken, user }: UsersState = useSelector(usersSelector)

  const [overflowOption, setOverflowOption] = React.useState<OverflowOption | undefined>()

  const overflowRule = first(user?.organization?.enterprise?.overflow_rules)

  useEffect(() => {
    if (!overflowRule) return

    setOverflowOption(OVERFLOW_OPTIONS[overflowRule.rule])
  }, [overflowRule])

  const handleUpdateOverflowRule = (overflowOption: OverflowOption) => {
    const rule = OVERFLOW_OPTIONS.indexOf(overflowOption)
    if (!overflowRule || !user) return

    dispatch(updateOverflowRuleAction(accessToken, user.id, overflowRule.id, rule))
    const msg = `${user.display_name} updated overflow to '${OVERFLOW_OPTIONS[rule]}'.`
    dispatch(slackMessageAction('stat', msg, { enterprise_id: user.organization.enterprise.id }))
  }

  if (!overflowOption) return null

  const isOff = overflowOption === OverflowOption.Off
  return (
    <div className="d-flex align-items-center pointer">
      <img alt="overflow" className={`mr-1 icon-xs transition-s ${isOff ? 'grayscale' : ''}`} src={overflow} />

      <p className={`text-s bold mb-0 transition-s ${isOff ? 'text--newbluegrayscale' : 'text--newblue'}`}>Overflow new</p>

      <div className="ml-2 d-flex gap-10px">
        {[OverflowOption.Off, OverflowOption.STAT, OverflowOption.All].map((o, idx) => {
          const isChecked = overflowOption === o
          return (
            <RadioButton
              key={idx}
              label={o}
              checked={isChecked}
              onClick={() => {
                if (!isChecked) handleUpdateOverflowRule(o)
              }}
            />
          )
        })}
      </div>
    </div>
  )
}
